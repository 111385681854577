import axios from 'axios';

// Crea una instancia de Axios con la configuración base
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ECOMMERCE_URL,
});

// Agrega un interceptor para las solicitudes
// axiosInstance.interceptors.request.use(function (config) {
//     // Modifica la configuración de la solicitud para agregar el encabezado CORS
//     config.headers['Access-Control-Allow-Origin'] = process.env.REACT_APP_ACCESS_CONTROL_ALLOW;
//     config.headers['Access-Control-Allow-Methods'] = 'POST, GET, PUT, OPTIONS';
//     config.headers['Access-Control-Allow-Headers'] = 'Content-Type';
//     config.headers['Content-Type'] = 'application/json';
//     return config;
// }, function (error) {
//     // Maneja errores de solicitud
//     return Promise.reject(error);
// });

export default axiosInstance;