import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes, routesMaintenceMode } from "./Routes";
import Loading from "../../components/Loading/Loading";
import { useMyContext } from "../../context/global/Context";
import { useEffect, useState } from "react";
import { isEmpty } from "../../utils/validation";

const router = createBrowserRouter(routes);
const routerMaintenance = createBrowserRouter(routesMaintenceMode);

export default function Router({ children }) {

    const { initialErrors, parameters } = useMyContext();

    const [isLoading, setIsLoading] = useState(true);
    const [routerDom, setRouter] = useState(null);

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = window.location.href;
        document.head.appendChild(link);
    }, []);

    useEffect(() => {
        if (!isEmpty(parameters)) {
            const parameter = parameters.find(e => e.tag === 'MODO_MANTENIMIENTO');

            if (!isEmpty(parameter)) {
                if (parameter.enabled === 1)
                    setRouter(routerMaintenance);
                else
                    setRouter(router);
            } else {
                setRouter(router);
            }
        }
    }, [parameters]);

    useEffect(() => {
        if (routerDom !== null)
            setIsLoading(false);
    }, [routerDom]);

    if (isLoading) {
        return (
            <>
                <Loading />
                {
                    initialErrors.length > 0 ?
                        <div className='text-start text-black font-normal px-10'>
                            <p className='text-base mb-6'>Se han encontrado los siguientes errores al cargar el sitio:</p>
                            <ul className='ps-10 list-disc'>
                                {
                                    initialErrors.map((e, i) => {
                                        return <li className='mb-3'>{e}</li>
                                    })
                                }
                            </ul>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }

    return (
        <RouterProvider router={routerDom} fallbackElement={<Loading size={'lg'} />}>
            {children}
        </RouterProvider>
    );
}