export const defaultValueBrands = [{ value: '', title: 'Seleccioná la marca' }];

export const defaultValueModels = [{ value: '', title: 'Seleccioná el modelo' }];

export const defaultValueUsage = [{ value: '', title: '¿Qué uso le das al vehículo? *' }];

export const defaultPostalCode = [{ value: '', title: 'Código Postal' }];

export const defaultSexSelector = [{ value: '', title: 'Seleccionar' }, { value: '1', title: 'Masculino' }, { value: '2', title: 'Femenino' }]

export const defaultCities = [{ value: '', title: 'Seleccione la localidad *' }];

export const defaultTypeVehicles = [{ value: '', title: 'Seleccionar' }, { value: 3, title: 'Auto' }, { value: 4, title: 'Moto' }];

export const defaultVehicleValue = {
    QuoteType: 'M',
    Anio: null,
    MarcaCodigo: null,
    MarcaDescripcion: '',
    ModeloCodigo: null,
    SubModeloCodigo: null,
    Descripcion: '',
    Motor: '',
    Chasis: '',
    Patente: '',
    Rama: '',
    CeroKm: false,
    Infoauto: {
        brand_id: null,
        brand_code: null,
        model_id: null,
        model_code: null,
        infoautoCodeByUsages: {},
        valueByUsagesByYear: {},
        sumAssured: 0
    }
};

export const defaultPersonForm = {
    Cuit: '',
    DocumentoNumero: '',
    Nombre: '',
    Apellido: '',
    FechaNacimiento: '',
    Sexo: '',
    Area: '',
    Telefono: '',
    EMail: '',
    Nacionalidad: 1,
    Direccion: {
        CodigoPostal: '',
        CalleNombre: '',
        CalleNumero: '',
        CallePiso: '',
        CalleDepto: ''
    }
};

export const defaultQuoteForm = {
    gnc: false,
    alarma: false,
    rastreo: false,
    vehicleUsage: '',
    postalCode: '',
    cityId: null,
    chasisNumber: '',
    motorNumber: '',
    ceroKm: false,
    noBearingCertificate: null,
    trackingFile: null,
    paymentMethod: '',
    dataPerson: defaultPersonForm
};

export const defaultBodyPolicy = {
    client: {
        document: '',
        email: '',
        name: '',
        last_name: '',
        area_code: '',
        cellphone: '',
        birthday: '',
        nacionality_id: 1,
        city_id: null,
        province_id: null,
        postal_code: null,
        address_street: '',
        address_number: null,
        address_floor: '',
        address_apartment: '',
        gender: '',
        civil_status_id: null,
        activity_id: ''
    },
    motorcycle: {
        domain: '',
        motor_number: '',
        motor_chassis: '',
        motorcycle_model_id: null,
        motorcycle_year: null
    },
    policyLife: null,
    payment: null,
    inspecciona: null,
    operationCode: '',
    quotation_coverage_id: null,
    sale_center: null
}

export const defaultTicketBody = {
    patent: null,
    product_coverage_hired: null,
    quotation_coverage_id: null,
    comment: '',
    reason: '',
    gateway_error: false,
}

export const defaultArraySteps = [
    {
        label: 'Verificá los datos de tu vehículo',
    },
    {
        label: 'Elegí tu plan',
    },
    {
        label: 'Completá tus datos',
    },
    {
        label: 'Elegí cómo contratar',
    },
    {
        label: 'Seleccioná la forma de pago',
    },
    {
        label: '¡Listo!',
    }
]

export const defaultCancellationForm = {
    Nombre: '',
    Apellido: '',
    Dni: '',
    Email: '',
    Area: '',
    Telefono: '',
    Ramo: 0,
    Patente: '',
    RecaptchaToken: ''
};

export const defaultContactForm = {
    Telefono: '',
    EMail: ''
}

export const defaultContactBody = {
    name: '',
    lastname: '',
    phone: '',
    email: '',
    message: '',
    sales_center: '',
}

export const defaultSaleCenterBody = {
    id: null,
    telefono: '',
    vendedor_tel: '',
    email: '',
    horario: '',
    emite_online: null,
    disable_phone_call: null,
    vendedor_emi_matricula: '',
    vendedor_emi_nombre: '',
    mostrar_info: false,
    no_bearing_enabled: true,
    products: []
}

export const defaultParameters = [
    {
        tag: "CONTRATAR_ONLINE",
        value: null,
        enabled: 1
    },
    {
        tag: "CONTRATAR_TELEFONO",
        value: null,
        enabled: 1
    },
    {
        tag: "MERCADOPAGO",
        value: null,
        enabled: 0
    },
    {
        tag: "TODOPAGO",
        value: null,
        enabled: 0
    },
    {
        tag: "MAX_ANIO_VIGENTE",
        value: new Date().getFullYear(),
        enabled: 1
    },
    {
        tag: "VENDEDOR_EMI",
        value: "0680207736",
        enabled: 1
    },
    {
        tag: "VENDEDOR_COT",
        value: "0680207736",
        enabled: 1
    },
    {
        tag: "MIN_ANIO_VIGENTE",
        value: new Date().getFullYear() - 30,
        enabled: 1
    },
    {
        tag: "DESVIO_MAXIMO",
        value: "10",
        enabled: 1
    },
    {
        tag: "DESVIO_MINIMO",
        value: "10",
        enabled: 1
    },
    {
        tag: "TELEFONO",
        value: "0810-345-0491",
        enabled: 1
    },
    {
        tag: "HORARIO",
        value: "Lunes a Viernes de 9 a 19 hs",
        enabled: 1
    },
    {
        tag: "ANTIGUEDAD_CERTIFICADO_NO_RODAMIENTO",
        value: "3",
        enabled: 1
    },
    {
        tag: "PESO_MAX_CERTIFICADO_NO_RODAMIENTO",
        value: "5",
        enabled: 1
    },
    {
        tag: "VENDEDOR_EMI_NOMBRE",
        value: "ASPEN BROKER  S.A.",
        enabled: 1
    },
    {
        tag: "VENDEDOR_EMI_MATRICULA",
        value: "001306",
        enabled: 1
    },
    {
        tag: "MODO_MANTENIMIENTO",
        value: null,
        enabled: 1
    },
    {
        tag: "TICKET_GLM_SOLICITUD_BAJA",
        value: "1",
        enabled: 1
    },
    {
        tag: "TICKET_GLM_CONTRATACION_TELEFONICA",
        value: "1",
        enabled: 1
    },
    {
        tag: "FECHA_VIGENCIA_COBERTURA_MINIMA",
        value: "2024-05-23",
        enabled: 1
    },
    {
        tag: "FECHA_VIGENCIA_COBERTURA_MAXIMA",
        value: "2024-05-26",
        enabled: 1
    }
];

export const defaultProducts = [
    {
        id: 100,
        section: 4,
        title: "Cotizá el mejor seguro para tu moto",
        description: "Moto",
        subtitle: null,
        img_src: "./assets/images/icons/moto.svg",
        finish_img_src: "./assets/images/icons/moto-finish.svg",
        has_objects: 0,
        benefits_collapsed: 0,
        is_vehicle: 1,
        min_age: 18,
        max_age: 65,
        uri: "motos"
    },
    {
        id: 101,
        section: 3,
        title: "Cotizá el mejor seguro para tu auto",
        description: "Auto",
        subtitle: null,
        img_src: "./assets/images/icons/car.svg",
        finish_img_src: "./assets/images/icons/car-finish.svg",
        has_objects: 0,
        benefits_collapsed: 0,
        is_vehicle: 1,
        min_age: 18,
        max_age: 65,
        uri: "autos"
    }
]

const tintIncompleted = '#262626';
const tintCompleted = '#45E22B';

export const defaultArrayColors = {
    dni: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    cuit: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    nombre: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    apellido: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    area: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    telefono: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    email: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    postalCode: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    calle: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    numero: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    piso: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    },
    depto: {
        color: tintIncompleted,
        colorActive: tintCompleted,
        completed: false
    }
}

export const defaultMaxValueGnc = {
    porc: 0,
    nominal: 0
}

export const defaultMinValueGnc = {
    porc: 0,
    nominal: 0
}

export const defaultBodyCbuForm = {
    holder: true,
    name: '',
    last_name: '',
    dni: '',
    cbu: '',
    bank: ''
}

export const defaultPaymentBody = {
    payment_method: 2,
    cbu: '',
    card_number: '',
    code_bank: '',
    bank: '',
    titular_name: '',
    titular_last_name: '',
    titular_document: '',
    expires_in: '',
    statusPago: ''
}

export const defaultPaymentData = [];

export const defaultBankList = {
    7: 'BANCO DE GALICIA Y BUENOS AIRES S.A.U.',
    11: 'BANCO DE LA NACION ARGENTINA',
    14: 'BANCO DE LA PROVINCIA DE BUENOS AIRES',
    15: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA',
    16: 'CITIBANK N.A.',
    17: 'BANCO BBVA ARGENTINA S.A.',
    20: 'BANCO DE LA PROVINCIA DE CORDOBA S.A.',
    27: 'BANCO SUPERVIELLE S.A.',
    29: 'BANCO DE LA CIUDAD DE BUENOS AIRES',
    34: 'BANCO PATAGONIA S.A.',
    44: 'BANCO HIPOTECARIO S.A.',
    45: 'BANCO DE SAN JUAN S.A.',
    65: 'BANCO MUNICIPAL DE ROSARIO',
    72: 'BANCO SANTANDER ARGENTINA S.A.',
    83: 'BANCO DEL CHUBUT S.A.',
    86: 'BANCO DE SANTA CRUZ S.A.',
    93: 'BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M',
    94: 'BANCO DE CORRIENTES S.A.',
    97: 'BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ',
    131: 'BANK OF CHINA LIMITED SUCURSAL BUENOS AI',
    143: 'BRUBANK S.A.U.',
    147: 'BIBANK S.A.',
    150: 'HSBC BANK ARGENTINA S.A.',
    158: 'OPEN BANK ARGENTINA S.A.',
    165: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO',
    191: 'BANCO CREDICOOP COOPERATIVO LIMITADO',
    198: 'BANCO DE VALORES S.A.',
    247: 'BANCO ROELA S.A.',
    254: 'BANCO MARIVA S.A.',
    259: 'BANCO BMA S.A.U.',
    266: 'BNP PARIBAS',
    268: 'BANCO PROVINCIA DE TIERRA DEL FUEGO',
    269: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY',
    277: 'BANCO SAENZ S.A.',
    281: 'BANCO MERIDIAN S.A.',
    285: 'BANCO MACRO S.A.',
    299: 'BANCO COMAFI SOCIEDAD ANONIMA',
    300: 'BANCO DE INVERSION Y COMERCIO EXTERIOR S',
    301: 'BANCO PIANO S.A.',
    305: 'BANCO JULIO SOCIEDAD ANONIMA',
    309: 'BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL',
    310: 'BANCO DEL SOL S.A.',
    311: 'NUEVO BANCO DEL CHACO S. A.',
    312: 'BANCO VOII S.A.',
    315: 'BANCO DE FORMOSA S.A.',
    319: 'BANCO CMF S.A.',
    321: 'BANCO DE SANTIAGO DEL ESTERO S.A.',
    322: 'BANCO INDUSTRIAL S.A.',
    330: 'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA',
    331: 'BANCO CETELEM ARGENTINA S.A.',
    332: 'BANCO DE SERVICIOS FINANCIEROS S.A.',
    338: 'BANCO DE SERVICIOS Y TRANSACCIONES S.A.',
    339: 'RCI BANQUE S.A.',
    340: 'BACS BANCO DE CREDITO Y SECURITIZACION S',
    341: 'BANCO MASVENTAS S.A.',
    384: 'WILOBANK S.A.U.',
    386: 'NUEVO BANCO DE ENTRE RÍOS S.A.',
    389: 'BANCO COLUMBIA S.A.',
    426: 'BANCO BICA S.A.',
    431: 'BANCO COINAG S.A.',
    432: 'BANCO DE COMERCIO S.A.',
    435: 'BANCO SUCREDITO REGIONAL S.A.U.',
    448: 'BANCO DINO S.A.',
    44077: 'COMPAÑIA FINANCIERA ARGENTINA S.A.',
    44088: 'VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F',
    44090: 'IUDU COMPAÑÍA FINANCIERA S.A.',
    44092: 'FCA COMPAÑIA FINANCIERA S.A.',
    44093: 'GPAT COMPAÑIA FINANCIERA S.A.U.',
    44094: 'MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT',
    44095: 'ROMBO COMPAÑÍA FINANCIERA S.A.',
    44096: 'JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.',
    44098: 'PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER',
    44099: 'TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA',
    45030: 'NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A.',
    45056: 'MONTEMAR COMPAÑIA FINANCIERA S.A.',
    45072: 'REBA COMPAÑIA FINANCIERA S.A.',
    65203: 'CRÉDITO REGIONAL COMPAÑÍA FINANCIERA S.A',
};