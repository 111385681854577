import { scrollToElement } from "./functions";

const validateRequiredFields = (id = '', formId = '') => {
    if (isEmpty(id)) {
        let element = document.querySelectorAll('input[required], select[required]');
        let spanError = document.querySelectorAll('.span_error');

        if (!isEmpty(formId)) {
            element = document.querySelectorAll(`#${formId} input[required], #${formId} select[required]`);
            spanError = document.querySelectorAll(`#${formId} .span_error`);
        }

        let cont = 0;

        spanError.forEach(e => {
            e.remove();
        });

        let scrollableElement = undefined;

        element.forEach((element, index) => {
            const value = element.getAttribute('value') || element.value;

            if (isEmpty(value)) {
                if (isEmpty(scrollableElement)) {
                    scrollableElement = element;
                }

                cont++;

                let span = document.createElement('span');
                span.innerText = 'Debe completar el campo obligatorio';
                span.classList = 'span_error text-red-err font-semibold text-xs mt-1 md:mt-2';
                span.setAttribute('key', `span_element_${index}_${element.tagName}`);

                element.parentElement.parentElement.append(span);
            }
        });

        scrollToElement(scrollableElement);

        return cont === 0;
    } else {
        const element = document.getElementById(id);
        const spanError = element.nextSibling;
        let cont = 0;

        if (spanError) { //significa que el span ya estaba activo. Quiere decir que el element tiene un valor vacío
            if (element.value !== '') {
                spanError.remove();
            } else {
                cont++;
            }
        }

        return cont === 0;
    }
}

const showErrorInput = (id, msg) => {
    const element = document.getElementById(id);
    const spanError = document.getElementById(`span_error_${id}`);

    if (spanError) spanError.remove();

    if (element) {
        let span = document.createElement('span');
        span.innerText = msg;
        span.classList = 'span_error text-red-err font-semibold text-xs';
        span.setAttribute('key', `span_element_ERROR_${id}`);

        element.parentElement.append(span);
    }
}

const validatePhoneNumber = (number) => {
    const lenArea = document.getElementById('area_phone').value.length;
    const lenPhone = document.getElementById('number_phone').value.length;

    if (lenArea > 4) return false;

    if (lenPhone > 8) return false;

    if (lenPhone + lenArea > 10) return false;

    return true;
}

const isEmpty = (param) => {
    return param === null || param === undefined || param === '' || param === 0 || param === '0' || param.length === 0;
}

const validateLicence = (license, branch) => {
    const motorcycleOld = /^[0-9]{3}[a-zA-Z]{3}$/;
    const motorcycleNew = /^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{3}$/;
    const carOld = /^[a-zA-Z]{3}[0-9]{3}$/;
    const carNew = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$/;

    if (license === 'A DECLARAR') return true;

    switch (parseInt(branch)) {
        case 3:
            return carOld.test(license) || carNew.test(license);
        case 4:
            return motorcycleOld.test(license) || motorcycleNew.test(license);
        default:
            return false;
    }
}

const onlyNumber = (value) => {
    const regex = /^\d+(,\s*\d+)*$/;

    if (isEmpty(value)) return true;

    return regex.test(value);
}

const onlyLetter = (value) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/;

    if (isEmpty(value)) return true;

    return regex.test(value);
}

const validateEmail = (value) => {
    const regex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

    return regex.test(value);
}

const withoutSpaces = (value) => {
    const regex = /^(?:\S+)?$/;

    return regex.test(value);
}

const withoutSpecialChars = (value, includeSpaces = false) => {
    const regex = includeSpaces ? /^[a-zA-Z0-9ñÑ\s]+$/ : /^[a-zA-ZñÑ0-9]+$/;

    if (isEmpty(value)) return true;

    return regex.test(value);
}

const validateCuit = (cuit) => {
    cuit = cuit.replace(/[^0-9]/g, '');

    if (cuit.length !== 11) {
        return false;
    }

    let acumulado = 0;
    let digitoVerificador = parseInt(cuit[10]);

    for (let i = 0; i < 10; i++) {
        let numero = parseInt(cuit[i]);
        let multiplicador = (i % 2 === 0) ? 1 : 2;
        let resultado = numero * multiplicador;

        if (resultado > 9) {
            resultado -= 9;
        }

        acumulado += resultado;
    }

    let digitoCalculado = 10 - (acumulado % 10);
    if (digitoCalculado === 10) {
        digitoCalculado = 0;
    }

    return digitoCalculado === digitoVerificador;
}

const sanitizePhoneNumber = (number) => {
    let auxNumber = '';
    const length = number.length;

    for (let i = 0; i < length; i++) {
        if (onlyNumber(number.charAt(i))) {
            auxNumber += number.charAt(i);
        }
    }

    return auxNumber;
}

const _isLengthOk = function (cbu) {
    return (cbu && cbu.length === 22);
}

const _isValidBankCode = function (code) {
    if (!code || code.length !== 8) {
        return false;
    }

    const bank = code.substr(0, 3);
    const checksumOne = code[3];
    const branch = code.substr(4, 3);
    const checksumTwo = code[7];

    const sum = bank[0] * 7 + bank[1] * 1 + bank[2] * 3 + checksumOne * 9 + branch[0] * 7 + branch[1] * 1 + branch[2] * 3;
    const diff = (10 - (sum % 10)) % 10; // the result of this should be only 1 digit

    return parseInt(diff) === parseInt(checksumTwo);
}

const _isValidAccount = function (acc) {
    if (!acc || acc.length !== 14) {
        return false;
    }

    const sum = acc[0] * 3 + acc[1] * 9 + acc[2] * 7 + acc[3] * 1 + acc[4] * 3 + acc[5] * 9 + acc[6] * 7 + acc[7] * 1 + acc[8] * 3 + acc[9] * 9 + acc[10] * 7 + acc[11] * 1 + acc[12] * 3;
    const diff = (10 - (sum % 10)) % 10; // the result of this should be only 1 digit
    const checksum = acc[13];

    return parseInt(diff) === parseInt(checksum);
}

const validateCbu = (cbu) => {
    if (isEmpty(cbu)) return false;

    const cvu = cbu.substr(0, 3);
    const bankCode = cbu.substr(0, 8);
    const accountCode = cbu.substr(8, 14);

    const cvuValidate = !(cvu === '000');
    const lengthOk = _isLengthOk(cbu);
    const validBankCode = _isValidBankCode(bankCode);
    const validAccount = _isValidAccount(accountCode);

    return cvuValidate && lengthOk && validBankCode && validAccount;
}

export { validateRequiredFields, validatePhoneNumber, isEmpty, validateLicence, onlyNumber, onlyLetter, validateEmail, showErrorInput, withoutSpaces, withoutSpecialChars, validateCuit, sanitizePhoneNumber, validateCbu };