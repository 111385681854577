import React from 'react';
import { redirect } from "react-router";
import { getItemStorage } from "../../context/storage";
import { QuotationVehicle, Home, Maintenance, CancellationRequest } from "../../pages";
import Template from "../template";
import { isEmpty } from "../../utils/validation";
import { defaultVehicleValue } from "../../config";

export const routes = [
    {
        path: "/",
        element: <Template />,
        children: [
            {
                index: true,
                element: <Home />,
                loader: async () => {
                    document.title = 'Cotizá tu seguro para motos y seguro para autos en ATM Seguros';

                    return null;
                }
            },
            {
                path: '/:vehicle_URL',
                element: <QuotationVehicle />,
                loader: async ({ params: { vehicle_URL } }) => {
                    // local storage tiene que tener valores por defecto ya que sino traba el ruteo
                    const currentProduct = getItemStorage('L', 'activeProduct');
                    const activeStep = getItemStorage('L', 'activeStep') ? parseInt(getItemStorage('L', 'activeStep')) : 1;
                    const vehicleData = getItemStorage('L', 'modelObject') ? getItemStorage('L', 'modelObject') : defaultVehicleValue;
                    // ============================================================================

                    if (currentProduct.uri !== vehicle_URL) {
                        return redirect(`/${currentProduct.uri}`);
                    }

                    if (activeStep === 1 && isEmpty(vehicleData.Anio)) {
                        return redirect('/');
                    }

                    switch (currentProduct.section) {
                        case 3:
                            document.title = 'Cotizá tu seguro para autos en ATM Seguros';
                            break;
                        case 4:
                            document.title = 'Cotizá tu seguro para motos en ATM Seguros';
                            break;
                        default:
                            document.title = 'Cotizá tu seguro para motos y seguro para autos en ATM Seguros';
                            break;
                    }

                    return null;
                }
            },
            {
                path: '/baja',
                element: <CancellationRequest />
            }
        ]
    }
];

export const routesMaintenceMode = [
    {
        path: '/',
        element: <Maintenance />,
        index: true
    }
]