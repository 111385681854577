import React, { useEffect, useState } from 'react';
import './ConfirmationScreen.css';
import PropTypes from 'prop-types';
import img_car from '../../assets/img/cotizar_auto.png';
import img_car_inspection from '../../assets/img/cotizar_auto_inspeccion.png';
import img_appStore from '../../assets/img/AppStore.png';
import img_googlePlay from '../../assets/img/GooglePlay.png';
import { Button } from '../basic';
import { ArrowRight, DoubleCheck } from '../../assets/icons';
import data from '../../services/ins_steps.json';
import Modal from '../Modal/Modal';
import { useMyContext } from '../../context/global/Context';
import { isEmpty, sanitizePhoneNumber } from '../../utils/validation';

const ConfirmationScreen = ({ done, handleClick = () => { }, loading = false, disabled = false, hireByPhone = false, withGnc = false }) => {
	const { sellPhoneNumber, openingHour, parametersSaleCenter } = useMyContext();

	const [recommendationsShow, setRecommendationsShow] = useState(false);
	const [phone, setPhone] = useState(sellPhoneNumber);
	const [hours, setHours] = useState(openingHour);

	useEffect(() => {
		if (parametersSaleCenter.mostrar_info === true) {
			setPhone(parametersSaleCenter.vendedor_tel);
			setHours(parametersSaleCenter.horario);
		}
	}, [parametersSaleCenter]);

	const classSizeImg = 'h-[38px]';

	const downloadComponent = (
		<>
			<p className='text-deep-burgundy font-bold text-lg text-center mb-4'>Descargar nuestra app</p>
			<div className="flex flex-row items-center justify-center gap-2 md:gap-4">
				<a href='https://play.google.com/store/apps/details?id=dibi.atmseguros' target='_blank' rel='noreferrer'>
					<img src={img_googlePlay} alt="img_googlePlay" className={classSizeImg} />
				</a>
				<a href='https://apps.apple.com/ar/app/atm-seguros/id1433020350?platform=iphone' target='_blank' rel='noreferrer'>
					<img src={img_appStore} alt="img_appStore" className={classSizeImg} />
				</a>
			</div>
		</>
	);

	if (hireByPhone) {
		return (
			<div className='confirmationscreen w-full mt-16'>
				<div className="flex flex-row md:grid md:grid-cols-2 justify-center gap-x-16">
					<div className="flex flex-col self-center px-6 md:px-0">
						<h1 className="text-center text-deep-magenta font-bold text-2xl md:text-4xl">¡Recibimos tu solicitud!</h1>
						<p className="text-center text-black font-bold text-lg md:text-xl mt-4 md:mt-8">Nos pondremos en contacto a la brevedad para terminar la contratación en forma telefónica. Por cualquier duda sobre este pedido comunicate al <a href={`${(parametersSaleCenter.mostrar_info === false) ? `tel:${phone}` : `https://api.whatsapp.com/send?phone=549${sanitizePhoneNumber(phone)}`}`}target='_blank' rel='noreferrer'>{phone}</a> de {hours}.</p>
						<div className="flex flex-col justify-center mt-8">
							{downloadComponent}
						</div>
					</div>
					<div className="hidden md:flex md:flex-col">
						<img src={img_car} alt='COTIZAR AUTO' />
					</div>
				</div>
			</div>
		);
	}

	const containerImgClass = `container_img_insp h-[80px] md:h-[90px] w-full border-2 rounded-lg border-black mb-3 content-center bg-white overflow-y-clip`;
	const imgClass = 'img_insp rounded-lg';

	return (
		<>
			<Modal show={recommendationsShow} showFunction={setRecommendationsShow}>
				<div className="flex flex-col text-base text-black md:px-6">
					<h3 className='font-semibold text-primary px-4 md:px-0'>Te adelantamos algunas recomendaciones:</h3>
					<ul className='my-3 md:my-4 text-sm px-4 md:px-0'>
						<li className='inline-flex items-center'>
							<DoubleCheck size={20} color={'#9EC54C'} className='mr-2' />
							<span>Tomar las fotos de día, al aire libre, con el auto limpio y los vidrios levantados.</span>
						</li>
						<li className='inline-flex items-center'>
							<DoubleCheck size={20} color={'#9EC54C'} className='mr-2' />
							<span>No se aceptarán fotos tomadas en diagonales, cortadas, parciales o veladas por la luz del sol.</span>
						</li>
						<li className='inline-flex items-center'>
							<DoubleCheck size={20} color={'#9EC54C'} className='mr-2' />
							<span>Incluir la cédula Verde del lado de los datos del vehículo.</span>
						</li>
					</ul>
					<h3 className='font-semibold text-primary px-4 md:px-0'>Ejemplos de cómo deben verse las fotos:</h3>
					<div className="grid grid-cols-2 md:grid-cols-3 gap-x-8 md:gap-x-14 gap-y-8 md:gap-y-16 mt-6 md:mt-8 px-4 md:px-0">
						{
							data.map(e => {
								if (e.onlyWithGnc === false) {
									return (
										<div className='flex flex-col max-w-[138px] md:max-w-[148px] mx-auto'>
											<div className={containerImgClass}>
												<img src={require(`../../assets/img/insp/${e.id}.png`)} alt={`${e.id}.png`} className={imgClass} />
											</div>
											<p className='text-center text-primary font-bold text-xs leading-4' dangerouslySetInnerHTML={{ __html: e.title }}></p>
										</div>
									);
								} else {
									return (<></>);
								}
							})
						}
					</div>
					{
						withGnc === true ?
							<>
								<h3 className='font-semibold text-primary px-4 md:px-0 mt-4 md:mt-6'>Debido a que la unidad tiene equipo de GNC, deben enviar también estas dos fotos:</h3>
								<div className="grid grid-cols-2 md:grid-cols-3 gap-x-8 md:gap-x-14 gap-y-8 md:gap-y-16 mt-6 px-4 md:px-0">
									{
										data.map(e => {
											if (e.onlyWithGnc === true) {
												return (
													<div className='flex flex-col max-w-[138px] md:max-w-[148px] mx-auto'>
														<div className={containerImgClass}>
															<img src={require(`../../assets/img/insp/${e.id}.png`)} alt={`${e.id}.png`} className={imgClass} />
														</div>
														<p className='text-center text-primary font-bold text-xs leading-4' dangerouslySetInnerHTML={{ __html: e.title }}></p>
													</div>
												);
											} else {
												return (<></>);
											}
										})
									}
								</div>
							</>
							:
							<></>
					}
					<div className="flex w-full justify-center mt-4 md:mt-6">
						<Button type={'primary'} title="cerrar" className={'rounded-lg border-2 py-2 font-semibold text-base uppercase mb-3 mt-2 hover:bg-opacity-85'} size={'md'} handleClick={() => { setRecommendationsShow(false) }} />
					</div>
				</div>
			</Modal>
			<div className='confirmationscreen w-full mt-8 md:mt-16'>
				{!done ?
					<div className="flex flex-row md:grid md:grid-cols-2 justify-center gap-x-16">
						<div className="flex flex-col self-center w-full md:w-auto">
							<h1 className="text-center text-deep-magenta font-bold text-2xl md:text-4xl">¡Felicidades!<br></br>Ya sos parte de ATM Seguros</h1>
							<p className="text-center text-black font-bold text-lg md:text-xl my-4 md:my-8 px-6 md:px-0">Te enviamos un mail con tu póliza<br></br>junto a información útil.</p>
							<div className="flex mx-6 md:mx-10">
								<Button title='descargar póliza' className={'bg-maroon rounded-lg text-white font-semibold text-base md:text-xl text-center py-3 uppercase hover:bg-opacity-85'} size={'full'} handleClick={handleClick} loading={loading} disabled={disabled} />
							</div>
							<div className="flex flex-col justify-center mt-8">
								{downloadComponent}
							</div>
						</div>
						<div className="hidden md:flex md:flex-col">
							<img src={img_car} alt='COTIZAR AUTO' />
						</div>
					</div>
					:
					<>
						<div className="flex flex-row md:grid md:grid-cols-2 justify-center md:gap-x-16">
							<div className="flex flex-col self-center">
								<h1 className="text-center text-deep-magenta font-bold text-2xl md:text-4xl">Tu vehículo<br></br>requiere inspección</h1>
								<p className="text-center text-black font-bold text-lg md:text-xl mt-4 md:mt-8 px-6 md:px-0">Te enviamos un mail con tu certificado provisorio y los pasos a seguir para la inspección.</p>
								<div className="flex flex-row self-center items-center mt-6 mb-8 cursor-pointer" onClick={() => { setRecommendationsShow(true) }}>
									<p className='text-center font-semibold text-primary text-sm'>Te adelantamos algunas recomendaciones</p>
									<ArrowRight size={18} color='#7C1338' className='ml-1' />
								</div>
								<div className="flex justify-center w-full">
									<div className="w-[90%] md:w-full">
										<Button title='descargar cert. provisorio de cobertura' className={'bg-maroon rounded-lg text-white font-semibold text-sm md:text-base text-center py-3 uppercase px-4 hover:bg-opacity-85 md:w-full'} handleClick={handleClick} loading={loading} disabled={disabled} />
									</div>
								</div>
								<div className="flex flex-col justify-center mt-8">
									{downloadComponent}
								</div>
							</div>
							<div className="hidden md:flex md:flex-col">
								<img src={img_car_inspection} alt='COTIZAR AUTO' className='max-h-[465px]' />
							</div>
						</div>
					</>
				}
			</div>
		</>
	);
};

ConfirmationScreen.propTypes = {
	rama: PropTypes.number,
	done: PropTypes.bool,
	handleClick: PropTypes.func,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	hireByPhone: PropTypes.bool,
	withGnc: PropTypes.bool,
};

export default ConfirmationScreen;