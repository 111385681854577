import React from 'react';
import gif from '../../assets/img/maintenance_mode.gif';

export default function Maintenance() {
    return (
        <div className="flex flex-col items-center">
            <h1 className="text-center text-primary font-semibold text-xl pt-60 pb-10">La página se encuentra en mantenimiento. Intente nuevamente más tarde.</h1>
            <img src={gif} className='max-w-max pb-60' alt="maintenance_mode_gif" />
        </div>
    );
}