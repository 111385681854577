import React, { useEffect, useState } from 'react';
import './CBUComponent.css';
import { useMyContext } from '../../context/global/Context';
import { Button, Check, Input, Label, Select } from '../basic';
import { ArrowLeft, DownArrow } from '../../assets/icons';
import { formatNumber } from '../../utils/format';
import BenefitsComponent from '../BenefitsComponent/BenefitsComponent';
import { useCbuData } from '../../hooks';
import { isEmpty, validateCbu, validateRequiredFields } from '../../utils/validation';
import ErrorModal from '../ErrorModal/ErrorModal';
import { getAssociatedBank, scrollToTop } from '../../utils/functions';

const CBUComponent = ({ handleStepBack = () => { } }) => {
	const { functions: { newPolicyFromCbu }, potencialPolicy } = useMyContext();
	const { cbuForm, modifyCbuForm } = useCbuData();

	const [modalBenefits, setModalBenefits] = useState(false);
	const [loading, setLoading] = useState(false);

	const [modalType, setModalType] = useState('plain');
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');
	const [modalShow, setModalShow] = useState(false);

	const className = 'uppercase border-2 border-b-shiny-pink border-t-white border-x-white px-4 font-semibold py-3 w-full placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded focus:outline-none h-full';
	const classNameLabel = 'uppercase text-left mb-1 ml-4 font-bold text-sm text-shiny-pink';

	useEffect(() => {
		if (!isEmpty(cbuForm.cbu)) {
			const bank = getAssociatedBank(cbuForm.cbu);
			modifyCbuForm({ bank: bank });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cbuForm.cbu]);

	const handleCbuForm = (value, target) => {
		switch (target) {
			case 'titular':
				modifyCbuForm({ holder: value });
				break;
			case 'nombre':
				modifyCbuForm({ name: value });
				break;
			case 'apellido':
				modifyCbuForm({ last_name: value });
				break;
			case 'documento':
				modifyCbuForm({ dni: value });
				break;
			case 'cbu':
				modifyCbuForm({ cbu: value });
				break;
			default:
				break;
		}
	}

	const handleSendForm = async () => {
		const resultValidation = validateRequiredFields('', 'cbu_form');

		const resultValidationCbu = validateCbu(cbuForm.cbu);

		if (resultValidation === true && resultValidationCbu === true) {
			setLoading(true);

			const responseNewPolicy = await newPolicyFromCbu(cbuForm);

			setLoading(false);
			scrollToTop();

			if (responseNewPolicy.error === true) {
				const { errors: { message } } = responseNewPolicy;

				setModalTitle(<p className='text-primary font-bold text-2xl'>Atención</p>);
				setModalText(
					<ul>
						{
							message.map(e => { return <li>{e}</li> })
						}
					</ul>
				);
				setModalType('error');
				setModalShow(true);
			}
		} else {
			let errors = [];

			if (resultValidation === false) {
				errors.push('Asegurate de completar todos los datos obligatorios antes de continuar.');
			}

			if (resultValidationCbu === false) {
				errors.push('Asegurate de ingresar un CBU válido.');
			}

			setModalTitle(<p className='text-primary font-bold text-2xl'>Atención</p>);
			setModalText(
				<ul>
					{
						errors.map(e => { return <li>{e}</li> })
					}
				</ul>
			);
			setModalType('error');
			setModalShow(true);
		}
	}

	return (
		<>
			<ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
			<BenefitsComponent show={modalBenefits} showFunction={setModalBenefits} />
			<div className='cbucomponent grid md:grid-cols-3 mt-14 md:gap-x-8 px-8 md:px-0'>
				<div className="flex flex-col text-center w-full md:w-auto">
					<div className="flex flex-col mb-6 md:mb-10">
						<h5 className="text-deep-wine-red uppercase font-bold text-xl whitespace-nowrap">Detalle de pago</h5>
						<h4 className="text-crimson-red uppercase font-semibold text-2xl">{potencialPolicy?.coverageTopTitle} {potencialPolicy?.coverageBottomTitle}</h4>
					</div>
					<div className="flex flex-col text-nowrap">
						<h5 className="text-primary text-md font-semibold line-through">{formatNumber(potencialPolicy?.cashFeeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
						<h5 className="text-black text-3xl font-semibold">{formatNumber(potencialPolicy?.feeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
					</div>
					<div className="flex flex-col mt-6 md:mt-8">
						<p className='uppercase text-primary font-semibold text-sm cursor-pointer inline-flex justify-center' onClick={() => { setModalBenefits(true) }}>detalle de la cobertura<DownArrow color='#7C1338' size={14} className='ms-3' /> </p>
					</div>
				</div>
				<div id="cbu_form" className='grid md:col-span-2 ms-4 md:ms-9'>
					<div className="grid md:grid-cols-2 md:gap-x-8 gap-y-6 md:gap-y-8 self-start">
						<div className="w-full md:col-span-2 flex justify-start py-8 md:py-0">
							<div className="flex flex-row md:flex-col">
								<Label title={'¿Es el titular de la cuenta?'} className={'text-left me-3 font-bold text-sm text-shiny-pink self-center'} />
								<div className="flex flex-row ms-3 md:ms-0 md:mt-2">
									<p className='uppercase text-black text-sm font-semibold self-center'>no</p>
									<Check className='me-2 ms-2' handleCheck={() => { handleCbuForm(!cbuForm.holder, 'titular') }} label={''} checked={cbuForm.holder} />
									<p className='uppercase text-black text-sm font-semibold self-center'>si</p>
								</div>
							</div>
						</div>
						{
							!cbuForm.holder ?
								<>
									<Input type='text' label={'DOCUMENTO *'} placeholder={''} className={className} size='w-full' value={cbuForm.dni} handleChange={(e) => { handleCbuForm(e.target.value, 'documento') }} maxLength={8} required />
									<Input type='text' label={'NOMBRE *'} placeholder={''} className={className} size='w-full' value={cbuForm.name} handleChange={(e) => { handleCbuForm(e.target.value, 'nombre') }} maxLength={25} required />
									<Input type='text' label={'APELLIDO *'} placeholder={''} className={className} size='w-full' value={cbuForm.last_name} handleChange={(e) => { handleCbuForm(e.target.value, 'apellido') }} maxLength={25} required />
								</>
								:
								<></>
						}
					</div>
					<div className={`grid md:grid-cols-2 md:gap-x-8 gap-y-6 md:gap-y-8 self-start ${cbuForm.holder === false ? 'mt-6 md:mt-8' : ''}`}>
						<div className="flex flex-col justify-between">
							<Label className={classNameLabel} title={'cbu *'} />
							<Input type='text' placeholder={''} className={className} size='w-full' value={cbuForm.cbu} handleChange={(e) => { handleCbuForm(e.target.value, 'cbu') }} maxLength={22} required />
						</div>
						<div className="flex flex-col justify-between">
							<Label className={classNameLabel} title={'banco'} />
							<Input type='text' placeholder={''} className={className + ' text-xs'} size='w-full' value={cbuForm.bank} editable={false} />
						</div>
					</div>
				</div>
			</div>
			<div className={`flex flex-col-reverse md:flex-row justify-center items-center md:items-start mt-10 md:mt-16 md:mx-24`}>
				<div className="flex w-full md:w-auto justify-center md:justify-start mt-3 md:mt-0">
					<Button title="volver al paso anterior" className={`border-0 py-2 font-semibold text-base uppercase bg-white text-light-grey-disabled flex flex-row items-center`} icon={<ArrowLeft size={23} color={'#A1AEB7'} className={'ms-4'} />} size={'auto'} handleClick={handleStepBack} disabled={loading} />
				</div>
				<div className="w-4/5 md:w-3/6 md:ms-14">
					<Button title="continuar" className={'text-white uppercase font-semibold text-sm bg-deep-cherry-red rounded-lg py-3 hover:bg-opacity-85'} size='full' handleClick={handleSendForm} loading={loading} disabled={false} />
				</div>
			</div>
		</>
	);
};

export default CBUComponent;