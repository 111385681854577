import './Contact.css';
import React, { useEffect, useState } from 'react';
import { Button, Input } from '../basic'
import { Facebook, Home, Instagram, LinkedIn, PhoneCall, TikTok, WhatsApp, X } from '../../assets/icons/lib';
import { useContactForm } from '../../hooks';
import { useMyContext } from '../../context/global/Context';
import { isEmpty, onlyNumber, sanitizePhoneNumber, validateEmail, validatePhoneNumber } from '../../utils/validation';
import ErrorModal from '../ErrorModal/ErrorModal';

const Contact = () => {
	const { functions: { handleContactForm }, sellPhoneNumber, openingHour, parametersSaleCenter } = useMyContext();
	const { contactForm, modifyContactFormForm } = useContactForm();

	const colorSocialIcons = '#262626';
	const sizeSocialIcons = 20;

	const [modalType, setModalType] = useState('plain');
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');
	const [modalShow, setModalShow] = useState(false);

	const [loadingContact, setLoadingContact] = useState(false);
	const [hideContact, setHideContact] = useState(false);

	useEffect(() => {
		setHideContact(parametersSaleCenter.mostrar_info);
	}, [parametersSaleCenter]);

	const modifyForm = (type, value) => {
		switch (type) {
			case 'telefono':
				if (onlyNumber(value)) modifyContactFormForm({ Telefono: value });
				break;
			case 'email':
				modifyContactFormForm({ EMail: value });
				break;
			default:
				break;
		}
	}

	const sendForm = async () => {
		setLoadingContact(true);

		const resultValidation = !isEmpty(contactForm.EMail) && !isEmpty(contactForm.Telefono);

		const resultValidationMail = validateEmail(contactForm.EMail);

		if (resultValidation && resultValidationMail) {
			const response = await handleContactForm(contactForm);

			if (response.error === false) {
				setModalTitle('Quiero que me contacten');
				setModalType('plain');
			} else {
				setModalTitle('¡Ups!');
				setModalType('error');
			}

			setModalText(response.msg);
			setModalShow(true);
		} else {
			setModalTitle('¡Ups!');

			if (resultValidationMail === false) {
				setModalText('Asegurate de ingresar un email válido.');
			}

			if (resultValidation === false) {
				setModalText('Asegurate de completar el teléfono y correo electrónico antes de continuar.');
			}

			setModalType('error');
			setModalShow(true);
		}

		setLoadingContact(false);
	}

	return (
		<>
			<ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
			<div id="contact_section" className='contact w-full'>
				<div className='w-full bg-primary py-6'>
					<div className='lg:w-3/4 xl:w-10/12 2xl:w-11/12 w-11/12 m-auto flex flex-col justify-center items-center py-10'>
						<div className='md:py-10 flex flex-col justify-center items-center'>
							<div>
								<h3 className='font-semibold text-xl md:text-2xl xl:text-3xl text-white mb-4 md:mb-10'>¿Necesitás ayuda con tu compra?</h3>
							</div>
							<div className='text-center font-normal text-base md:text-xl text-white'>
								<p>Nuestros expertos en seguros pueden asesorarte para contratar tu seguro ideal.</p>
								<p className={parametersSaleCenter.mostrar_info === false ? '' : 'hidden'}>Llamá al <span className='font-bold'>{sellPhoneNumber}</span> de {openingHour} o escribinos y te ayudamos.</p>
							</div>
						</div>
						<div className='py-10 flex flex-col items-center md:items-top md:w-auto w-full justify-between gap-y-5 md:flex-row md:gap-x-5'>
							<div className='flex flex-1 lg:w-[340px] md:w-[240px] w-full'>
								<Input className={''} size='w-full' type='tel' name='telefono' placeholder={'Ingresá tu teléfono'} border='border-[1.5px] border-shiny-pink rounded' value={contactForm.Telefono} handleChange={(e) => modifyForm('telefono', e.target.value)} maxLength={10} />
							</div>
							<div className='flex flex-1 lg:w-[340px] md:w-[240px] w-full'>
								<Input className={''} size='w-full' type='text' name='mail' placeholder={'Ingresá tu correo electrónico'} border='border-[1.5px] border-shiny-pink rounded' value={contactForm.EMail} handleChange={(e) => modifyForm('email', e.target.value)} />
							</div>
							<div className='flex flex-1 lg:w-[340px] md:w-[240px] w-full'>
								<Button className={'text-white bg-stone font-semibold border-stone rounded-xl py-3.5 hover:bg-opacity-85'} size='full' handleClick={() => { sendForm() }} title="QUIERO QUE ME CONTACTEN" loading={loadingContact} />
							</div>
						</div>
					</div>
				</div>
				<div className='w-full bg-light-red py-6'>
					<div className='lg:w-3/4 xl:w-10/12 2xl:w-11/12 w-11/12 m-auto flex flex-col justify-center items-center'>
						<div className='pt-10 flex flex-col justify-center items-center'>
							<div>
								<h3 className='font-semibold text-xl md:text-2xl xl:text-3xl text-primary text-center text-pretty'>{isEmpty(parametersSaleCenter.vendedor_emi_nombre) ? 'Otras formas de contacto' : `Contactate con ${parametersSaleCenter.vendedor_emi_nombre}`}</h3>
								{
									!isEmpty(parametersSaleCenter.vendedor_emi_matricula) ?
										<p className='text-base md:text-lg font-thin text-black md:mt-2 text-center'>Matrícula Nº {parametersSaleCenter.vendedor_emi_matricula}</p>
										:
										<></>
								}
							</div>
							<div className='text-center font-normal text-lg md:text-xl text-primary'>
								{
									isEmpty(parametersSaleCenter.texto_libre) ?
										<></>
										:
										<p className='my-4'>{parametersSaleCenter.texto_libre}</p>
								}
								<p className={`${isEmpty(parametersSaleCenter.texto_libre) ? 'my-4' : 'mb-4 text-sm md:text-base'}`}>{isEmpty(parametersSaleCenter.horario) ? 'Encontrá la manera más conveniente de comunicarte con nosotros.' : `De ${parametersSaleCenter.horario}`}</p>
							</div>
						</div>
						<div className='flex lg:flex-row gap-y-10 justify-between items-start pb-10 flex-col 2xl:gap-x-48 lg:gap-x-24 w-auto'>
							<div className="flex flex-col">
								<div className="flex flex-row">
									<h4 className='font-semibold text-black text-lg md:pb-2'>Cotizá y contratá tu póliza</h4>
								</div>
								<div className='flex flex-row items-center'>
									<div className='p-2 ps-0'>
										{
											parametersSaleCenter.mostrar_info === false ?
												<PhoneCall size={24} color={'#262626'} opacity={1.0} />
												:
												<WhatsApp size={20} color={'#262626'} opacity={1.0} />
										}
									</div>
									<div className='font-semibold text-black text-base p-2 text-center'>
										<a href={parametersSaleCenter.mostrar_info === false ? `tel:${sanitizePhoneNumber(sellPhoneNumber)}` : `https://api.whatsapp.com/send?phone=549${sanitizePhoneNumber(parametersSaleCenter.vendedor_tel)}`} target='_blank' rel="noreferrer">{isEmpty(parametersSaleCenter.vendedor_tel) ? sellPhoneNumber : parametersSaleCenter.vendedor_tel}</a>
									</div>
								</div>
							</div>
							<div className="flex flex-col">
								<div className="flex flex-row">
									<h4 className='font-semibold text-black text-lg md:pb-2'>Atención a asegurados</h4>
								</div>
								<div className='flex flex-row items-center'>
									<div className='p-2 ps-0'>
										<PhoneCall size={24} color={'#262626'} opacity={1.0} />
									</div>
									<div className='font-semibold text-black text-base p-2'>
										<a href={isEmpty(parametersSaleCenter.telefono) ? 'tel:08103450492' : `tel:549${sanitizePhoneNumber(parametersSaleCenter.telefono)}`} target='_blank' rel='noreferrer'>{isEmpty(parametersSaleCenter.telefono) ? '0810-345-0492' : parametersSaleCenter.telefono}</a>
									</div>
								</div>
								<div className='flex flex-row items-center'>
									<div className='p-2 ps-0'>
										<Home size={24} color={'#262626'} opacity={1.0} />
									</div>
									<div className='font-semibold text-black text-base p-2'>
										<a href={`mailto:${isEmpty(parametersSaleCenter.email) ? 'contacto@atmseguros.com.ar' : parametersSaleCenter.email}`} target='_blank' rel='noreferrer'>{isEmpty(parametersSaleCenter.email) ? 'contacto@atmseguros.com.ar' : parametersSaleCenter.email}</a>
									</div>
								</div>
							</div>
							{
								hideContact === true ?
									<></>
									:
									<div className="flex flex-col">
										<div className="flex flex-row">
											<h4 className='font-semibold text-black text-lg md:pb-2'>Atención a productores</h4>
										</div>
										<div className='flex flex-row items-center'>
											<div className='p-2 ps-0'>
												<PhoneCall size={24} color={'#262626'} opacity={1.0} />
											</div>
											<div className='font-semibold text-black text-base p-2'>
												<a href='tel:08103450546' target='_blank' rel='noreferrer'>0810-345-0546</a>
											</div>
										</div>
										<div className='flex flex-row items-center'>
											<div className='p-2 ps-0'>
												<Home size={24} color={'#262626'} opacity={1.0} />
											</div>
											<div className='font-semibold text-black text-base p-2'>
												<a href='mailto:productores@atmseguros.com.ar' target='_blank' rel='noreferrer'>productores@atmseguros.com.ar</a>
											</div>
										</div>
									</div>
							}
						</div>
						{
							isEmpty(parametersSaleCenter.redes) ?
								<></>
								:
								<>
									<div className="flex flex-row flex-wrap justify-center gap-y-8 gap-x-10 md:gap-y-0 md:gap-x-8">
										{
											!isEmpty(parametersSaleCenter.redes.instagram) ?
												<div className='flex flex-row items-center'>
													<Instagram size={sizeSocialIcons} color={colorSocialIcons} />
													<a href={`https://www.instagram.com/${parametersSaleCenter.redes.instagram}`} className='font-semibold text-black text-base ps-2' target='_blank' rel='noreferrer'>{parametersSaleCenter.redes.instagram}</a>
												</div>
												:
												<></>
										}
										{
											!isEmpty(parametersSaleCenter.redes.facebook) ?
												<div className='flex flex-row items-center'>
													<Facebook size={sizeSocialIcons} color={colorSocialIcons} />
													<a href={`https://www.facebook.com/${parametersSaleCenter.redes.facebook}`} className='font-semibold text-black text-base ps-2' target='_blank' rel='noreferrer'>{parametersSaleCenter.redes.facebook}</a>
												</div>
												:
												<></>
										}
										{
											!isEmpty(parametersSaleCenter.redes.tiktok) ?
												<div className='flex flex-row items-center'>
													<TikTok size={sizeSocialIcons} color={colorSocialIcons} />
													<a href={`https://www.tiktok.com/${parametersSaleCenter.redes.tiktok}`} className='font-semibold text-black text-base ps-2' target='_blank' rel='noreferrer'>{parametersSaleCenter.redes.tiktok}</a>
												</div>
												:
												<></>
										}
										{
											!isEmpty(parametersSaleCenter.redes.linkedin) ?
												<div className='flex flex-row items-center'>
													<LinkedIn size={sizeSocialIcons} color={colorSocialIcons} />
													<a href={`https://www.linkedin.com/in/${parametersSaleCenter.redes.linkedin}`} className='font-semibold text-black text-base ps-2' target='_blank' rel='noreferrer'>{parametersSaleCenter.redes.linkedin}</a>
												</div>
												:
												<></>
										}
										{
											!isEmpty(parametersSaleCenter.redes.twitter) ?
												<div className='flex flex-row items-center'>
													<X size={sizeSocialIcons} color={colorSocialIcons} />
													<a href={`https://x.com/${parametersSaleCenter.redes.twitter}`} className='font-semibold text-black text-base ps-2' target='_blank' rel='noreferrer'>{parametersSaleCenter.redes.twitter}</a>
												</div>
												:
												<></>
										}
									</div>
								</>
						}
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;
