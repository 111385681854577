import React, { useEffect, useState } from 'react';
import ConfirmationScreen from '../../ConfirmationScreen/ConfirmationScreen';
import './Step6.css';
import { useMyContext } from '../../../context/global/Context';
import { useFetchGoogleAnalytics } from '../../../hooks';
import { defaultSaleCenterBody } from '../../../config';

const Step6 = () => {
	const { functions: { getPdfDataDownload, handleSaleCenterBody }, policy, errorPolicy, documentDownloaded, hireByPhone } = useMyContext();
	const { purchase } = useFetchGoogleAnalytics(6);

	const [inspection, setInspection] = useState(false);
	const [loadingDownload, setLoadingDownload] = useState(false);
	const [downloadDisabled, setDownloadDisabled] = useState(false);
	const [gncIncluded, setGncIncluded] = useState(false);

	useEffect(() => {
		return () => {
			handleSaleCenterBody(defaultSaleCenterBody);
		};
	}, []);

	useEffect(() => {
		setDownloadDisabled(documentDownloaded);
	}, [documentDownloaded]);

	useEffect(() => {
		if (policy) {
			purchase();
			setInspection(policy.status === 'INS');
			setGncIncluded(policy.gnc_included);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [policy]);

	const handleDocument = async () => {
		setLoadingDownload(true);

		if (policy) {
			await getPdfDataDownload();
		} else {
			console.log(errorPolicy);
		}

		setLoadingDownload(false);
	}

	return (
		<div className='step6'>
			<ConfirmationScreen withGnc={gncIncluded} done={inspection} handleClick={() => { handleDocument() }} loading={loadingDownload} disabled={downloadDisabled} hireByPhone={hireByPhone} />
		</div>
	);
};

export default Step6;