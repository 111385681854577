import PropTypes from 'prop-types';
import './Input.css';
import React from 'react';
import { Label } from '../Label';
import { isEmpty } from '../../../utils/validation';

export default function Input({ type = 'text', label = '', name = '', placeholder = '', border = '', handleChange = () => { }, className = '', value = '', size = 'w-full', editable = true, inputIcon = <></>, required = false, checked = false, maxLength = 255, max = '', min = '', accept = '*' }) {
	let labelComponent;

	if (typeof label === 'string') {
		labelComponent = <Label className={'text-left mb-1 ml-4 font-bold text-sm text-shiny-pink'} htmlFor={name} title={label} />;
	} else if (React.isValidElement(label)) {
		labelComponent = <React.Fragment>{label}</React.Fragment>
	} else {
		labelComponent = <></>;
	}

	const inputClass = className !== '' ? className : `${border} ${size} px-4 font-bold py-3 w-full placeholder:font-regular placeholder:text-black placeholder:text-base placeholder:font-normal focus:rounded`;

	return (
		<div className='input w-full'>
			<div className={`flex flex-col`}>
				{
					isEmpty(label) ?
						<></>
						:
						labelComponent
				}
				<div className="relative">
					<input type={type} id={name} name={name} onChange={handleChange} placeholder={placeholder} className={`${inputClass} ${editable === false ? 'pointer-events-none' : ''} focus:outline-none`} value={value} required={required} checked={checked} maxLength={maxLength} max={max} min={min} accept={accept} />
					{inputIcon}
				</div>
			</div>
		</div>
	);
};

Input.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	name: PropTypes.string,
	placeholder: PropTypes.string,
	border: PropTypes.string,
	handleChange: PropTypes.func,
	value: PropTypes.any,
	editable: PropTypes.bool,
	inputIcon: PropTypes.element,
	required: PropTypes.bool,
	checked: PropTypes.bool,
	maxLength: PropTypes.number,
	max: PropTypes.string,
	accept: PropTypes.string,
};