import React, { useEffect, useState } from 'react';
import './Step5.css';
import { useMyContext } from '../../../context/global/Context';
import { formatNumber } from '../../../utils/format';
import ConfirmForm from '../../ConfirmForm/ConfirmForm';
import { ArrowLeft, CorazonMano, CreditCard, DownArrow, Money } from '../../../assets/icons';
import { Button, Check } from '../../basic';
import Modal from '../../Modal/Modal';
import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions';
import { scrollToTop } from '../../../utils/functions';
import BenefitsComponent from '../../BenefitsComponent/BenefitsComponent';
import { useFetchGoogleAnalytics } from '../../../hooks';
import ErrorModal from '../../ErrorModal/ErrorModal';
import { isEmpty } from '../../../utils/validation';
import CBUComponent from '../../CBUComponent/CBUComponent';

const Step5 = () => {
	const { functions: { handleStepQuotation, handlePaymentProcess, handleHashIframe, checkStatusPayment, handleTermsAndNews }, hashIframe, potencialPolicy, currentBranch, checkPayment, quoteForm } = useMyContext();
	const { addPaymentInfo } = useFetchGoogleAnalytics(5);

	const [hash, setHash] = useState('');
	const [loadingPayment, setLoadingPayment] = useState(false);
	const [termsChecked, setTermsChecked] = useState(false);
	const [newsChecked, setNewsChecked] = useState(false);
	const [paymentType, setPaymentType] = useState('');

	const [modalTerms, setModalTerms] = useState(false);
	const [modalBenefits, setModalBenefits] = useState(false);

	const [msgTerms, setMsgTerms] = useState(false);

	const [modalType, setModalType] = useState('plain');
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');
	const [modalShow, setModalShow] = useState(false);

	const [cbuView, setCbuView] = useState(false);

	const typePayment = {
		creditCard: ['02', '04', '05', '06', '07', '08', '09', '10'],
		cbu: ['11']
	}

	useEffect(() => {
		setHash(hashIframe);
	}, [hashIframe]);

	useEffect(() => {
		if (!isEmpty(quoteForm.paymentMethod)) {
			setPaymentType(quoteForm.paymentMethod);
		}
	}, [quoteForm]);

	useEffect(() => {
		let intervalId;

		if (checkPayment === true) {
			intervalId = setInterval(async () => {
				await checkStatusPayment();
			}, 5000);
		}

		return () => {
			clearInterval(intervalId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkPayment]);

	const handlePaymentStep = async () => {
		setMsgTerms(false);

		if (termsChecked === true) {
			setLoadingPayment(true);

			const paymentCard = typePayment.creditCard.find((value) => value === paymentType);
			const paymentCbu = typePayment.cbu.find((value) => value === paymentType);

			if (!isEmpty(paymentCard)) {
				await handlePaymentProcess(currentBranch, termsChecked, newsChecked)
					.then((response) => {
						if (response.error === false) {
							scrollToTop();
							setHash(response.body);
							addPaymentInfo();
						} else {
							setHash('');

							setModalTitle('Atención');
							setModalText(response.msg);
							setModalType('error');
							setModalShow(true);
						}
					});
			}

			if (!isEmpty(paymentCbu)) {
				scrollToTop();
				const termsUpdated = await handleTermsAndNews(termsChecked, newsChecked);

				setCbuView(termsUpdated);

				if (termsUpdated === false) {
					setModalTitle('Atención');
					setModalText('Hubo un error al actualizar los términos y condiciones.');
					setModalType('error');
					setModalShow(true);
				}
			}

			setLoadingPayment(false);
		} else {
			setMsgTerms(true);
		}
	}

	const handleStepBack = () => {
		if (hash === '') {
			handleStepQuotation(4);
		} else {
			handleHashIframe('');
		}
	}

	const handleCheckTermsModal = () => {
		setTermsChecked(true);
		setModalTerms(false);
	}

	const handleNews = () => {
		setNewsChecked(!newsChecked);
	}

	const handleModalTerms = () => {
		setModalTerms(true);
	}

	const handleCheckTerms = () => {
		setTermsChecked(!termsChecked);
		setMsgTerms(false);
	}

	if (cbuView === true) {
		return <CBUComponent handleStepBack={() => { scrollToTop(); setCbuView(false); }} />
	}

	return (
		<>
			<ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
			<Modal show={modalTerms} showFunction={() => { setModalTerms(!modalTerms) }} type={'confirm'} handleConfirm={handleCheckTermsModal} title={<TermsAndConditions />} handleClose={() => { setModalTerms(false) }} />
			<BenefitsComponent show={modalBenefits} showFunction={setModalBenefits} />
			<div className='step5'>
				<div className={`flex flex-col md:flex-row items-start ${hash !== '' ? 'mt-14' : 'mt-12 md:mt-24'} gap-12`}>
					<div className="flex flex-col text-center w-full md:w-auto">
						<div className="flex flex-col mb-6 md:mb-10">
							<h5 className="text-deep-wine-red uppercase font-bold text-xl whitespace-nowrap">Detalle de pago</h5>
							<h4 className="text-crimson-red uppercase font-semibold text-2xl">{potencialPolicy?.coverageTopTitle} {potencialPolicy?.coverageBottomTitle}</h4>
						</div>
						<div className="flex flex-col text-nowrap">
							<h5 className="text-primary text-md font-semibold line-through">{formatNumber(potencialPolicy?.cashFeeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
							<h5 className="text-black text-3xl font-semibold">{formatNumber(potencialPolicy?.feeValue, 'es-AR', 'currency', 'ARS')} /mes</h5>
						</div>
						<div className="flex flex-col mt-6 md:mt-8">
							<p className='uppercase text-primary font-semibold text-sm cursor-pointer inline-flex justify-center' onClick={() => { setModalBenefits(true) }}>detalle de la cobertura<DownArrow color='#7C1338' size={14} className='ms-3' /> </p>
						</div>
					</div>
					<div className={`flex flex-col w-full ${hash === '' ? 'mb-8 mt-4 md:mb-0 md:mt-0' : ''}`}>
						{
							hash !== '' ?
								<iframe src={`${process.env.REACT_APP_URL_PROCESADOR_OK}/index.php?id=${hash}&iframe=1`} title="Mi Iframe" width="100%" height="500px" frameBorder="0"></iframe>
								:
								<ConfirmForm />
						}
						{
							!isEmpty(hash) ?
								<></>
								:
								<div className='grid grid-flow-row-dense'>
									{
										msgTerms === true ?
											<div className="w-full text-center my-6 md:my-8">
												<span className='text-sm lg:text-base text-red-err font-semibold'>Debe aceptar los términos y condiciones antes de continuar.</span>
											</div>
											:
											<></>
									}
									<div className={`mx-0 bg-white px-8 md:px-0 ${msgTerms === false ? 'mt-8' : ''}`}>
										<div className="grid md:grid-flow-col-dense justify-center gap-x-10 mb-6">
											<div className="flex flex-row items-center">
												<div className="me-3">
													<Check checked={termsChecked} handleCheck={handleCheckTerms} />
												</div>
												<p className='text-xs md:text-sm font-normal'>Aceptar <span className='underline text-primary cursor-pointer font-semibold' onClick={() => { handleModalTerms() }}>Términos y condiciones</span>.</p>
											</div>
											<div className="flex flex-row items-center mt-4 md:mt-0">
												<div className="flex me-3">
													<Check checked={newsChecked} handleCheck={handleNews} />
												</div>
												<p className='text-xs md:text-sm font-normal'>Deseo recibir novedades sobre ATM Seguros.</p>
											</div>
										</div>
										<div className="flex flex-col">
											<p className='text-sm md:text-sm font-normal text-black text-justify'>IMPORTANTE: Para un mayor servicio los planes incluyen una cobertura de VIDA individual  destinada a cubrir el fallecimiento por enfermedad o accidente del titular de la póliza contratada  y un seguro automotor / motovehículo en las condiciones aquí especificadas. El costo informado corresponde a ambas coberturas. Para las personas físicas con condición de consumidor final. Desde 18 hasta 65 años. Una única póliza de vida con su seguro de moto o auto (el primero en contratarse).</p>
										</div>
									</div>
								</div>
						}
					</div>
				</div>
				<div className={`flex flex-col-reverse md:flex-row justify-center items-center ${hash !== '' ? 'md:mt-10' : 'mt-6 md:mt-16'} md:mx-24`}>
					<div className="flex w-full md:w-auto justify-center md:justify-start mt-3 md:mt-0">
						<Button title="volver al paso anterior" className={`border-0 py-2 font-semibold text-base uppercase bg-white text-light-grey-disabled flex flex-row items-center`} icon={<ArrowLeft size={23} color={'#A1AEB7'} className={'ms-4'} />} size={'auto'} handleClick={handleStepBack} disabled={false} />
					</div>
					{
						hash === '' ?
							<>
								<div className="w-4/5 md:w-3/6 md:ms-14">
									<Button title="continuar" className={'text-white uppercase font-semibold text-sm bg-deep-cherry-red rounded-lg py-3 hover:bg-opacity-85'} size='full' handleClick={() => { handlePaymentStep() }} loading={loadingPayment} disabled={false} />
									{
										msgTerms === true ?
											<div className="flex justify-center md:justify-start mt-2 md:mt-1">
												<span className='text-xs text-red-err font-semibold text-center md:text-start'>Debe aceptar los términos y condiciones antes de continuar.</span>
											</div>
											:
											<></>
									}
								</div>
							</>
							:
							<></>
					}
				</div>
			</div>
		</>
	);
};

export default Step5;